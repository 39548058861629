import React from 'react';
import { Link } from 'gatsby';
import { FooterParagraph } from './shared.css';

const Imprint = () => (
  <FooterParagraph>
    Copyright © Bending Spoons S.p.A. | Via Nino Bonnet 10, 20154, Milan, Italy | VAT, tax code, and number of registration with the Milan Monza Brianza Lodi Company Register 08931860962 | REA number MI 2056926 | Contributed capital €128.816,34
    <br />
    <br />
    <Link to="/support/">Help Center</Link> -{' '}
    <a href="https://bendingspoons.com/tos.html?app=1099771240" target="blank">
      Terms & Conditions
    </a>{' '}
    -{' '}
    <a href="/privacy-policy" target="blank">
      Website Privacy and Cookie Policy
    </a>
  </FooterParagraph>
);

export default Imprint;
